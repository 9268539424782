import { Container } from 'components/shared'
import styled from 'styled-components'
const StyledWelcome = styled(Container)`
    width: 100%;
    flex-direction: row;
    overflow: hidden;
    justify-content: flex-start;
    position: relative;
    height: 100vh;
    background-color: white;
    overflow-y: scroll;
    padding-bottom: 90px !important;
    padding-left: 30px;
    .title-container{
        height: 90px;
        width: 100%;
        overflow: hidden;
        position: fixed;
        height: 100px;
        background: white;
        z-index: 99;
        top: 72px;
        .h3{
            text-align: center;
        }
    }
    >.h4{
        margin-top: 40px;
        margin-left: 40px;
        padding-top: 60px;
        margin-bottom: 20px;
        width: 100%;
    }

    .cards-categories-container{
        display: grid;
        width: 100%;
        position: absolute;
        top: 20px;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-template-areas: "favorites favorites favorites favorites";
        .cards-category-container{
            display: flex;
            flex-direction:column;
            :first-child{
                >.h4{
                    margin-top: 60px;
                }
            }
            >.h4{
                margin-top: 0px;
                margin-left: 40px;
                padding-top: 20px;
                margin-bottom: 10px;
                width: 100%;
            }
            .workbook-list{
                flex-direction: row;
                flex-wrap: wrap;
                width: 100%;
                justify-content: flex-start;
                align-items: flex-start;
                margin-left: 40px;
                margin-top:20px;
                height: 220px;
                &.favorites-container{ 
                    .star-icon{
                        :hover{
                            path{
                                fill: #F2F2F2;
                                stroke: #1932A0;
                            }
                        }
                    }
                    .fav{
                        display: inline-block;
                        path{
                                fill: #FFCB45;
                                stroke: none;
                        }
                    }
                }
                &.lastUsed-container, &.suggested-container{
                    .star-icon{
                        :hover{
                            path{
                                fill: #FFCB45;
                                stroke: none;
                            }
                        }
                        &.fav{
                            display: inline-block;
                            path{
                                fill: #FFCB45;
                                stroke: none;
                            }
                        }    
                    }
                }
            }
            &.favorites-wrapper-container{
                grid-area: favorites;
            }
            &.lastUsed-wrapper-container{
                grid-area: 2/1;
                
            }
            &.suggested-wrapper-container{
                grid-area: 2/2;
            }
        }
    }
`

export default StyledWelcome