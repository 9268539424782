import { Container, WorkbookViewer, Text, WorkbookActions, Spinner } from 'components'
import { hooks, mainSelectors , authSelectors, mainOperations} from 'state'
import StyledDashboard from './dashboard-styled'
import { useEffect, useState, memo, useMemo } from 'react'
import WorkbookPreview from 'components/layout/dashboard/WorkbookPreview/workbook-preview'
import { LoadingSpinner, StarIcon } from 'assets/icons'
import { useParams } from 'react-router-dom'
import { getWorkbookTierIcon } from '../utils'
import Sidebar from '../components/layout/Sidebar'
import { ICard, ICardCategory } from '../utils/constants/interfaces'

export const Dashboard = memo(() => {
  const { useAppSelector, useAppDispatch } = hooks
  const { selectAuth } = authSelectors
  const { user } = useAppSelector(selectAuth)
  const dispatch = useAppDispatch()
  const { addFavoriteCard } = mainOperations
  const { selectmain, selectWorkbookTier } = mainSelectors
  const { workbookPreview, selectedSuscriptionIndex, selectedWorkbookV2, isSidebarOpen, workbookCards} = useAppSelector(selectmain)
  let subscriptionTier = user.subscriptions[selectedSuscriptionIndex].subscriptionTier
  const workbookTier = useAppSelector(selectWorkbookTier)
  const params = useParams()
  const convertArrayToObject = (array:{imageUrl:string,screenSize: string}[]) => {
    return Object.fromEntries(array.map((a:{imageUrl:string, screenSize:string})=>Object.values(a).reverse()))
  }
  const [workbookImage, setWorkbookImage] = useState()
  const hasSubscription =  (subscriptionTier === workbookTier)
  useEffect(()=>{
    if(workbookPreview?.workbookPreviewImages?.length! > 0){
      setWorkbookImage(convertArrayToObject(workbookPreview?.workbookPreviewImages!))
    }
  },[workbookPreview])

  const cardsCategory = useMemo(()=>{
    return workbookCards.find((worbookCard: ICardCategory)=>{
      return worbookCard.cards.find((c:ICard)=>c.workbookGuid === selectedWorkbookV2?.workbookGuid)
    })
  },[selectedWorkbookV2?.workbookGuid, workbookCards])
  const renderWorkbookActions = () => {
    if(selectedWorkbookV2?.workbookGuid !== params.id){
      return <Spinner text={"Loading..."} size='200'><LoadingSpinner/></Spinner> 
    }else if(cardsCategory && workbookCards.length > 0){
      const matchedCard = cardsCategory?.cards.find((c:ICard)=>c.workbookGuid === selectedWorkbookV2?.workbookGuid)
      return (<>
        <Container className={'title-container left-side'}>
          <Text textStyle='h3'>{selectedWorkbookV2?.workbookDisplayName}</Text>
          <StarIcon className={`star-icon ${matchedCard?.isFavorite ? 'fav':''}`} onClick={(e)=>handleOnClickStar(e,matchedCard)}/>
        </Container>
        <WorkbookActions />
      </>)
    }
  }


  const handleOnClickStar = (e:React.MouseEvent, card: ICard)=>{
    e.stopPropagation()
    dispatch(addFavoriteCard({...card,isFavorite: !card.isFavorite}))
  }

  const icon =  getWorkbookTierIcon(workbookTier,undefined)
  return (
    <StyledDashboard expand className={'dashboard'}>
          <Container className={'workbooks-section'}>
            {      
              hasSubscription ? 
                <>
                  {renderWorkbookActions() }
                  {<WorkbookViewer/>}
                </>
                : workbookImage && <WorkbookPreview {...workbookPreview!} workbookImage={workbookImage} icon={icon} />
            }
          </Container>
          <Container className={`${'sidebar-section-container'} ${isSidebarOpen ? ' expanded' : ' colapsed'}`} >
              <Sidebar />
          </Container>
    </StyledDashboard>
  )
})

export default Dashboard