import * as React from "react"
const SvgComponent = (props:React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={19}
    fill="none"
    {...props}
  >
    <path
      fill="#F2F2F2"
      stroke="#1932A0"
      d="m10 .993 2.967 5.088.111.19.216.047 5.76 1.246-3.927 4.39-.147.165.022.22.594 5.858-5.395-2.375-.201-.089-.201.089-5.395 2.375.593-5.859.023-.219-.147-.164-3.927-4.39 5.76-1.247.216-.046.11-.19L10 .991Z"
    />
  </svg>
)
export default SvgComponent
