import styled from "styled-components"
import { Container, Text } from "components/shared"
import { StarIcon } from "../../../../assets"
import { getColor } from "utils"
import { ISection as Card, ICard } from "utils/constants/interfaces"
import { useNavigate } from "react-router-dom"
import { devices } from "utils/constants/media-queries"


const StyledPreviewCard = styled(Container)<{className:string,cardTitle:string, previewImage: any}>`
    width: 170px;
    height: 170px;
    @media only screen and ${devices["2xl"]}{
        width: 200px;
        height: 200px;
    }
    flex-direction: column;
    margin-right: 25px;
    margin-bottom: 25px;
    position: relative;
    background-color: transparent;
    border-radius: 20px;
    :hover{
        transform: scale(1.1);
        box-shadow: 10px 16px 0 rgba(0,0,0,0.1),0 6px 20px 0 rgba(0,0,0,0.1) !important;
    }
    .card-image-container{
        width: inherit;
        height: 200px;
        border: 1px solid ${getColor('brand')};
        border-radius: 20px;
        position: relative;
        background-image: url(${({previewImage})=>previewImage});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: -5px;
        .star-icon{
            width: fit-content;
            position: absolute;
            bottom: 0; 
            right: 0;
            margin: 12px;
            z-index: 9;
            :hover{
                cursor: pointer;
            }
        }
    }
    .card-description-container{
        position: absolute;
        bottom: 0;
        background-color: white;
        border: 1px solid ${getColor('brand')};
        width: inherit;
        border-end-start-radius: 20px;
        border-end-end-radius: 20px;
        .h4{
            margin: 10px;
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 130px;
            white-space: wrap;
            text-align: center;
        }
    }
    
`

const PreviewCard = ({card, starIcon }:{card: ICard, starIcon: any}) => {
    const navigate = useNavigate()
    const handleOnClick = () =>{
        const title = card.workbookName.toLowerCase().replaceAll(' ','_')
        navigate(`/${title}/${card.workbookGuid}`)
    }
    return(<StyledPreviewCard className={'card'} previewImage={card.previewImage} cardTitle={card.workbookName} onClick={handleOnClick}>
        <Container className={'card-image'}>
            {starIcon}
        </Container>
        <Container className={'card-description'}><Text textStyle="h4">{card.workbookName}</Text></Container>
    </StyledPreviewCard>)
}

export default PreviewCard