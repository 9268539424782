import { Text } from 'components/shared/ui-elements/'
import StyledWelcome from './welcome-styled'
import { Container } from '../../../shared'
import PreviewCard from './preview-card'
import { StarIcon, StarIconDisabled } from '../../../../assets'
import { ICard, ICardCategory } from "utils/constants/interfaces"
import { hooks, mainOperations, mainSelectors } from "state"

const Welcome = () =>{
    const { useAppSelector, useAppDispatch } = hooks
    const dispatch = useAppDispatch()
    const { addFavoriteCard } = mainOperations
    const { selectmain } = mainSelectors
    const { workbookCards } = useAppSelector(selectmain)

    const handleOnClickStar = (e:React.MouseEvent, selectedCard: ICard)=>{
        e.stopPropagation()
        dispatch(addFavoriteCard({...selectedCard,isFavorite: !selectedCard.isFavorite}))
    }
    
    return(<StyledWelcome className={'welcome'}>
        <Container className={'title'}>
            <Text textStyle='h3'>Welcome</Text>
        </Container>
        <Container className={'cards-categories'}>
            {
                workbookCards.length > 0 && workbookCards.map((wc: ICardCategory,index)=>{
                    return <Container className={`cards-category-container ${wc.card}-wrapper`}>
                        <Text textStyle='h4'>{wc.description}</Text>
                        <Container className={`workbook-list ${wc.card}`}>
                            {
                                wc.cards.map((card:ICard)=>{
                                    const startIcon = card.isFavorite ? <StarIcon className={`star-icon ${card.isFavorite ? 'fav' : ''}`} onClick={(e)=>handleOnClickStar(e,card)}/> : <StarIconDisabled className={`star-icon ${card.isFavorite ? 'fav':''}`} onClick={(e)=>handleOnClickStar(e,card)}/>
                                    return (<PreviewCard key={card.workbookGuid} card={card} starIcon={startIcon}></PreviewCard>)
                                })
                            }
                        </Container>
                    </Container>
                })
            }
        </Container>
    </StyledWelcome>)
}

export default Welcome