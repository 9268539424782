import { Button, Container, DropdownMenu, Text } from "components"
import { authSelectors, dataTrackingOperations, hooks, mainOperations, mainSelectors, store } from "state"
import { getAsset, getSelectedValues } from 'utils';
import { FiChevronRight, FiMenu } from "react-icons/fi"
import { useEffect, useState } from "react"
import { useNavigate, useLocation, useParams } from "react-router-dom"
import StyledHeader from "./header-styled"
import { screenSizes } from "utils/constants/media-queries"
import useScreenSize from "utils/hooks/useScreenSize"
import { IFiltersGroup, ISavedFilter, ISigmaControl, IWorkbook } from "../../utils/constants/interfaces"
import { eventsCategory, navigationEvents, options_menu } from "../../utils/constants/events"
import { getWorkbookTierIcon } from "utils"
import { DownloadIcon } from "../../assets";
import { premiumSub } from "utils/constants"


export const Header = () => {
  const state = store.getState()
  const [screenWidth] = useScreenSize()
  const { isSidebarOpen } = state.main
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false)
  const [wait, setWait] = useState(false)
  const [focusHeader, setFocusHeader] = useState(false)
  const [className, setClassName] = useState('')
  const [ optionSelected , setOptionSelected ] = useState({groupName: '', workbook: ''})
  const { selectAuth } = authSelectors;
  const { selectWorkbookGroup, getWorkBookUrlV2, getUserReports, setSidebarOpen, setMainContent, getSavedWorkbookFilters, getWorkbookElements, updateSelectedFilterSaved, saveWorkbookFilters, updateDefaultFilterSaved, getWorkbookPreview, setSelectedWorkbookGuid, setSelectedModalContent, setModalOpen, getExportPremiumList} = mainOperations;
  const { selectHeaderTabs,selectmain, selectWorkbookTabs } = mainSelectors;
  const navigate = useNavigate()
  const { useAppSelector, useAppDispatch } = hooks
  const { selectedWorkbookGroup, appType, selectedWorkbookV2, savedWorkbookFilters, selectedSuscriptionIndex, workbookFilters, defaultFilterSaved, isModalOpen } = useAppSelector(selectmain)
  const workbookTabs = useAppSelector(selectWorkbookTabs)
  const { trackEvent } = dataTrackingOperations

  const { user } = useAppSelector(selectAuth)
  const auth = useAppSelector(selectAuth)
  const params = useParams();
  const location = useLocation();
  const dispatch = useAppDispatch()
  const headerTabs = useAppSelector(selectHeaderTabs)
  const signOut = async () => {
    await dispatch(trackEvent({ eventCategory: 'switch', values: JSON.stringify({ targetApp: 'PAM' }), event: 'switch_on_logout' }))
    window.location.assign(`${process.env.REACT_APP_LANDING_URL}/logout?originApp=${appType}`)
  }

  const workbookList = workbookTabs?.map((workbook: any) => ({ label: workbook.tabLabel, key: workbook.workbookGuid }))
  const selectNewWorkbook = (({ label: tabLabel, key }: any) => {
    navigate(`${tabLabel.replaceAll(' ', '_').toLowerCase()}/${key}`)
  })

  const handleOnClickPremiumDownload = ()=>{
    dispatch(setModalOpen(true))
    dispatch(setMainContent('premiumDownload'))
    dispatch(setSelectedModalContent('premiumDownload'))
  }
  const handleChange = async (values: { label: string, key: string }) => {
    await dispatch(trackEvent({ eventCategory: eventsCategory.navigate, values: { workbookGuid: values.key, workbookName: values.label }, event: navigationEvents.view_workbook }))
    return values && selectNewWorkbook(values)
  }

  const renderHeaderLink = (label: string, icon: any) => {
    const isSelected = optionSelected.groupName === label.toUpperCase()
    const isActive = label === selectedWorkbookGroup 
    return (
      <Container key={label} className={`${isActive ? 'active ' : ''}${isSelected && location.pathname !== '/welcome' ? 'selected ' : ''}header-link`} onClick={async () => dispatch(selectWorkbookGroup(label))}>
        <DropdownMenu
          onHover={() => dispatch(selectWorkbookGroup(label))}
          label={label}
          options={workbookList || []}
          onChange={handleChange}
          arrorDirection="up-down"
          icon={icon}
        />
      </Container>
    )
  }


  const checkWorkbookSubscriptionTier = () => {
    return user.subscriptions[selectedSuscriptionIndex].workbooks.find((wb: IWorkbook)=>
      wb.workbookGuid === params.id!
    )?.groupTier
  }

  let subscriptionTier = user.subscriptions[selectedSuscriptionIndex].subscriptionTier
  let isPremiumDownloadAllowed =  user.subscriptions[selectedSuscriptionIndex].settings.find((s:any)=>s.downloadPremiumAllowed)

  useEffect(() => {
    if (!!params.id) {
      const matchedWorkbook = user.subscriptions[selectedSuscriptionIndex].workbooks.find(({ workbookGuid }: any) => workbookGuid === params.id)
      let controlFilter = ''
      let groupString = ''
      if(matchedWorkbook){
        controlFilter = matchedWorkbook.defaultedTo
        groupString = matchedWorkbook.groupString
      } 
      setOptionSelected({groupName: groupString, workbook: params.category!})
      dispatch(setSelectedWorkbookGuid(params.id!))
      if(subscriptionTier === checkWorkbookSubscriptionTier()){
        dispatch(getExportPremiumList())
        dispatch(getUserReports(params.id!))
        dispatch(setSelectedWorkbookGuid(params.id))
        dispatch(getWorkbookElements(params.id))
        dispatch(getSavedWorkbookFilters(params.id!))
      }else{
        dispatch(setSelectedWorkbookGuid(params.id))
        dispatch(getWorkbookPreview(params.id))
      }
    }
  }, [params.id])

  const getPrioritizedSavedFilter = () => {
    let prioritizedSavedFilter = undefined
    if (selectedWorkbookV2?.selectedSavedFilter) {
      return selectedWorkbookV2?.selectedSavedFilter
    }

    if (defaultFilterSaved) {
      return prioritizedSavedFilter = defaultFilterSaved
    }
    if (!prioritizedSavedFilter && savedWorkbookFilters && savedWorkbookFilters?.length > 0) {
      prioritizedSavedFilter = savedWorkbookFilters?.find((swf: ISavedFilter) => swf.isAutoSaving === true)
    }
    return prioritizedSavedFilter
  }
  
  useEffect(() => {
    if (wait || focusHeader) {
      setClassName('focusHeader header')
    }
    if (!wait && !focusHeader) {
      setClassName('header')
    }
  }, [focusHeader])

  useEffect(() => {
    if (!wait) {
      if (!focusHeader) {
        setClassName('header')
      }
    }
  }, [wait])

  useEffect(() => {
    if (location.pathname === '/') {
      // dispatch(setSelectedWorkbookGuid(params.id))
      // const wb = [...auth.user.subscriptions[selectedSuscriptionIndex].workbooks].sort((a: any, b: any) => a.groupOrder - b.groupOrder)[0]
      // dispatch(trackEvent({ eventCategory: eventsCategory.navigate, values: { workbookGuid: wb.workbookGuid, workbookName: wb.workbookDisplayName }, event: navigationEvents.view_workbook }))
      // navigate(`${wb.workbookDisplayName.replaceAll(' ', '_').toLowerCase()}/${wb.workbookGuid}`)
      navigate('welcome')
    }
  }, [location.pathname])

  useEffect(() => {
    async function saveWbFilters(wbGuid: string, sigmaControls: ISigmaControl[]) {
      const filtersGroup: IFiltersGroup = {
        workbookGuid: wbGuid,
        filters: getSelectedValues(workbookFilters!),
        isDefault: false,
        isAutoSaving: true,
        savedFilterName: '',
        sigmaControls: sigmaControls ? sigmaControls : []
      }
      if (params.id === selectedWorkbookV2?.workbookGuid && sigmaControls) {
        await dispatch(saveWorkbookFilters(filtersGroup))
      }
      let checkedFilters = undefined
      let savedFilter = getPrioritizedSavedFilter()
      if (selectedWorkbookV2?.selectedSavedFilter) {
        checkedFilters = undefined
      } else {
        checkedFilters = getSelectedValues(workbookFilters!)
      }
      if (checkedFilters) {
        await dispatch(getWorkBookUrlV2({
          workbookGuid: params.id,
          filters: checkedFilters,
          savedFilter: savedFilter || null
        }));
      } else {
        await dispatch(getWorkBookUrlV2({
          workbookGuid: params.id,
          savedFilter: savedFilter || null
        }));
      }

      dispatch(updateSelectedFilterSaved(undefined))
      dispatch(updateDefaultFilterSaved(undefined))
    }
    if (workbookFilters?.length! > 0) {
      saveWbFilters(params.id!, selectedWorkbookV2?.sigmaControls)
    }
  }, [workbookFilters])

  return (
    <StyledHeader
      onMouseLeave={() => {
        if (!isSideMenuOpen) {
          setFocusHeader(false);
          setWait(true);
          setTimeout(() => { setWait(false) }, 300);
        }
      }}
      onMouseEnter={() => setFocusHeader(true)}
      className={`${className}`}
    >
      <Container className={'header-left'}>
        <Container className={'header-logo'} onClick={()=> navigate('welcome')}>
          {getAsset('name')()}
        </Container>
        <Container className={"links"}>
          {
            headerTabs.map((ht:any)=>{
              let icon =getWorkbookTierIcon(ht.groupTier, subscriptionTier)
              return renderHeaderLink(ht.groupString, icon )
            })
          }
          { isPremiumDownloadAllowed &&
          <Container className={"premium-download"} >
            <button onClick={handleOnClickPremiumDownload}><p style={{display:'inline-block',marginRight:'5px'}}>Download</p><DownloadIcon style={{fill:'none !important'}}width={80}/></button>
          </Container>
          }
        </Container>
      </Container>
      <Container className={'header-right'} onClick={() => {
        if (screenWidth <= screenSizes.tablet || isSidebarOpen) {
          dispatch(setMainContent(''))
          dispatch(setSidebarOpen(!isSidebarOpen))
          return
        }
        setIsSideMenuOpen(!isSideMenuOpen)
      }} >
        <Button>
          <FiMenu size={'20px'} />
        </Button>
        <Text color="white">Menu</Text>
      </Container>
      <Container className={`${isSideMenuOpen ? 'visible ' : ' '}outer-area`} onClick={() => setIsSideMenuOpen(false)} />
      <Container className={`${isSideMenuOpen ? 'visible ' : ' '}side-menu`} >
        <Container className={`side-menu-content`}>
          <Button
            onClick={() => {
              dispatch(trackEvent({ eventCategory: eventsCategory.navigate, values: options_menu.contact_us, event: navigationEvents.options_menu }))
              window.open('mailto:support@pistildata.com?subject=Subject&body=Body%20goes%20here')
            }}
          >
            <Text>Contact Us</Text><FiChevronRight size={16} />
          </Button>
          <Button
            onClick={() => {
              dispatch(trackEvent({ eventCategory: eventsCategory.navigate, values: options_menu.help_center, event: navigationEvents.options_menu }))
              window.open('https://helpcenter.pistildata.com/')
            }}
          >
            <Text>Help Center</Text><FiChevronRight size={16} />
          </Button>
          <Button onClick={() => signOut()}>
            <Text>Sign Out</Text><FiChevronRight size={16} />
          </Button>
        </Container>
      </Container>
    </StyledHeader>
  )
}
export default Header