import * as React from "react"
const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={19}
    fill="none"
    {...props}
  >
    <path
      fill={props.fill}
      d="m10 0 3.4 5.83L20 7.257l-4.5 5.031.68 6.712L10 16.28 3.82 19l.68-6.712L0 7.258 6.6 5.83 10 0Z"
    />
  </svg>
)
export default SvgComponent
